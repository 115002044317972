import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const InputField = forwardRef(
  (
    {
      className,
      disabled = false,
      error = false,
      full = false,
      iconLeft = false,
      iconRight = false,
      type = 'text',
      ...rest
    },
    ref
  ) => (
    <input
      ref={ref}
      className={twMerge(
        'text-ellipsis rounded-2 border border-solid border-neutral-high-500 bg-neutral-high-100 py-3 text-sm text-neutral-low-100',
        'placeholder:text-neutral-high-500 focus:border-primary-300',
        'dark:border-neutral-low-100 dark:bg-neutral-low-300 dark:text-neutral-high-200 dark:placeholder:text-neutral-high-200 dark:focus:border-primary-100',
        'lg:py-2 lg:text-base',
        iconRight ? 'pr-14' : 'pr-4',
        iconLeft ? 'pl-12' : 'pl-4',
        error &&
          'border-error-300 placeholder:text-error-300 focus:border-error-300 dark:border-error-200 dark:placeholder:text-error-200 dark:focus:border-error-200',
        disabled &&
          'cursor-not-allowed select-none bg-neutral-high-300 placeholder:text-neutral-high-500 dark:bg-neutral-low-300 dark:placeholder:text-neutral-low-100',
        full && 'w-full',
        className
      )}
      disabled={disabled}
      type={type}
      {...rest}
    />
  )
);

InputField.displayName = 'InputField';

InputField.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  full: PropTypes.bool,
  iconLeft: PropTypes.bool,
  iconRight: PropTypes.bool,
  type: PropTypes.string,
};

export default InputField;
